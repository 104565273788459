import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { Link } from 'gatsby';
import Circles from '../components/circles';
import Numbers from '../components/numbers';
import BoxGrid from '../components/box-grid';
import Partners from '../components/partners';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';
import { navigate } from "gatsby";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container mdxType="Container">
  <Row className="px-4" mdxType="Row">
   <Col xs={12} sm={12} lg={7} mdxType="Col">
          <h2 className="mt-1">Project summary</h2>
          <p>{`The BY-COVID project ran between 2021 and 2024.`}</p>
          <p>{`BeYond-COVID (BY-COVID) aims to provide comprehensive open data on SARS-CoV-2 and other infectious diseases across scientific, medical, public health and policy domains. It will strongly emphasise mobilising raw viral sequences, helping to identify and monitor the spread of SARS-CoV-2 variants. The project will further accelerate access to SARS-CoV-2 and COVID-19 and linking patient and research data.`}</p>
          <p>{`To ensure interoperability of national and global efforts, BY-COVID will enable federated data analysis compliant with data protection regulations, harmonise and manage metadata and sample identifiers, and facilitate long-term cataloguing.`}</p>
          <p>{`BY-COVID will build on the One-Health approach, exploit and contribute to the European Open Science Cloud and work closely with the ISIDORe project funded through HORIZON Europe.`}</p>
          <p>{`The project will integrate established national and European infrastructures with ELIXIR, BBMRI, ECRIN, PHIRI and CESSDA. It will build on existing efforts, such as the COVID-19 Data Platform and the Versatile Emerging infectious disease Observatory project (VEO), maximising efficiency. It will also develop synergies with the European Health Data Space.`}</p>
          <p>{`In an unprecedented and unique interdisciplinary effort, BY-COVID will bring together 53 partners from 19 countries and stakeholders from the biomedical field, hospitals, public health, social sciences and humanities.`}</p>
          <p>{`Ultimately, it will improve European readiness for future pandemics, enhance genomic surveillance and rapid-response capabilities. In addition, BY-COVID serves as a demonstrator of interdisciplinary work across country borders. The project's outputs will allow scientists across multiple domains, including SMEs and industry, to access varied data with the potential to generate new knowledge on infectious diseases.`}</p>
        </Col>
      </Row>
    </Container>
    {/* BY-COVID IN NUMBERS */}
    <Circles circlesTitle="BY-COVID in numbers" circle1="Euros" circle1Value="12M" circle2="Partners" circle2Value="53" circle3="Countries" circle3Value="19" circle4="Months" circle4Value="36" mdxType="Circles" />
    {/* OBJECTIVES */}
    <Numbers numbersTitle="Objectives" one="Enable storage, sharing, access, analysis and processing of research data and other digital research objects from outbreak research." two="Mobilise and expose viral and human infectious disease data from national centres." three="Link FAIR data and metadata on SARS-CoV-2 and COVID-19." four="Develop digital tools and data analytics for pandemic and outbreak preparedness, including tracking genomic variations of SARS-CoV-2 and identifying new variants of concern." five="Contribute to the Horizon Europe European Open Science Cloud (EOSC) Partnership & European Health Data Space (EHDS)." mdxType="Numbers" />
    {/* PROJECT STRUCTURE */}
    <BoxGrid boxGridTitle="Project structure"
    /*
    * WP1
    */ box1Title="WP1: Support for virological analyses in emerging disease outbreaks" box1Description="Work Package 1 will establish and improve SARS-CoV-2 Data Hubs that, with a globally comprehensive viral sequence and normative variation data sets, provide the foundation for linking genomic surveillance with heterogeneous data across domains. An open call will allow additional Data Hubs to be established and packaging of a rapid deployment &quot;preparedness&quot; Data Hub addresses future pathogen outbreaks." box1Names="Guy Cochrane (EMBL-EBI), Clara Amid (Erasmus MC)" box1Subtext="Objectives 2, 4"
    /*
    * WP2
    */ box2Title="WP2: Accessing heterogeneous data across domains and jurisdictions for enabling the downstream processing of COVID-19 and future pandemic episodes data" box2Description="Work Package 2 brings together data resources and catalogues across domains, captures data governance and access procedures. It will align metadata descriptions and other relevant semantic information first within domains (e.g., biomolecular and imaging, clinical and health, survey, etc) and in a second stage (in alignment with WP3 developments) expose a reference catalogue with harmonised metadata descriptions across domains." box2Names="Alfonso Valencia/Salvador Capella-Gutierrez (BSC), Antje Keppler (EuroBioImaging)" box2Subtext="Objectives 1, 2, 4, 5"
    /*
    * WP3
    */ box3Title="WP3: COVID-19 integration platform" box3Description="Work Package 3 is focussed on services for the discovery, integration and citation of COVID-19 data by delivering a flexible, tiered metadata discovery system across different domains, metadata standards, and maturity/robustness levels of data sources. This will enable the linking of FAIR data and metadata on SARS-CoV-2 and COVID-19, other infectious diseases and related data, and ultimately increase the potential for collaboration and exploitation of data." box3Names="Henning Hermjakob (EMBL-EBI), Mari Kleemola (CESSDA/TAU-FSD)" box3Subtext="Objectives 2, 3, 5"
    /*
    * WP4
    */ box4Title="WP4: Connecting the COVID-19 data platform to analysis tools and local portals " box4Description="Work Package 4 will develop, aggregate and integrate tools for analysis and visualisation of data in the COVID-19 Data Platform. It will provide a provenance framework for tracking of derived data and the transparency and trustworthiness of results which will ultimately improve trust in science. Researchers will be enabled to exploit the large volumes of data for tasks such as identification of variants of concern." box4Names="Frederik Coppens (VIB), Petr Holub (BBMRI-ERIC)" box4Subtext="Objectives 3, 4, 5"
    /*
    * WP5
    */ box5Title="WP5: A continuously evolving demonstrator project feeding the changing research questions that surface during an on-going pandemic to solutions" box5Description="Work Package 5 will demonstrate usability of BY-COVID services across disciplines and national borders through continuously evolving demonstrator projects. It will assess viral variants and disease outcomes using real world data, as well as the effectiveness of vaccines against new variants using retrospective clinical trial data and improve the understanding of the mechanistic determinants of variant responses." box5Names=" Nina Van Goethem (Sciensano), Enrique Bernal Delgado (IACS)" box5Subtext="Objectives 3, 4"
    /*
    * WP6
    */ box6Title="WP6: Engage, train and build capacity with national and international stakeholders" box6Description="Work Package 6 will focus on stakeholder engagement, bottom-up by facilitating knowledge exchange in relation to setting up surveillance systems for pathogens, but also top-down to shape the policy landscape (EOSC, EHDS, and more broadly intergovernmental organisations and funders). Importantly, WP6 coordinates the training and capacity-building that takes place across WPs ensuring alignment and visibility with stakeholders." box6Names="Patricia Palagi (SIB), Corinne S. Martin (ELIXIR Hub)" box6Subtext="Objectives 1, 3, 4, 5"
    /*
    * WP7
    */ box7Title="WP7: Outreach, Communication, industry and public engagement" box7Description="Work Package 7 will develop and implement the project-wide communications and outreach strategy, ensuring key stakeholders including scientists, industry and SMEs, policy makers and the public have awareness of the project and opportunities to engage, use project outputs and provide feedback to partners." box7Names="Andy Smith (ELIXIR Hub), Elaine Harrison (ELIXIR Hub)"
    /*
    * WP8
    */ box8Title="WP8: Coordination, project management and Ethical, Legal and Social Implications (ELSI)" box8Description="WP8 will oversee the project execution. It will ensure effective coordination so that the project goals, benefits and expected impact are delivered within time, scope, and budget. It will also address the implementation of ELSI (ethical and legal) aspects, and the data management and sustainability plan." box8Names="Juan Arenas Marquez, Andrea Troncoso (ELIXIR Hub)" mdxType="BoxGrid" />
    {/* COVID-19 Platform */}
    <Container mdxType="Container">
  <Row className="px-4" mdxType="Row">
   <Col mdxType="Col">
   <span className="anchor" id="data-platform"></span>
   <h2>The European COVID-19 Data Platform</h2>
          <p>{`Rapid and open sharing of data greatly accelerates research and discovery, which is essential to respond to the COVID-19 pandemic. The European Commission, EMBL’s European Bioinformatics Institute (EMBL-EBI) and ELIXIR, together with EU Member States and other research partners, operate a dedicated European COVID-19 Data Platform. This Platform enables the rapid collection and comprehensive data sharing of available research data from different sources for the European and global research communities.`}</p>
          <p>{`The European COVID-19 Data Platform consists of three connected components:`}</p>
          <ol>
            <li parentName="ol">
              <p parentName="li"><strong parentName="p"><a parentName="strong" {...{
                    "href": "https://www.covid19dataportal.org/data-hubs"
                  }}>{`SARS-CoV-2 Data Hubs`}</a>{`:`}</strong>{` can be used to organise SARS-CoV-2 genomic sequence data at national, regional and institute levels. Upon release, this data can then be openly shared with the global research community via the COVID-19 Data Portal.`}</p>
            </li>
            <li parentName="ol">
              <p parentName="li"><strong parentName="p"><a parentName="strong" {...{
                    "href": "https://www.covid19dataportal.org/federated-ega"
                  }}>{`Federated European Genome-phenome Archive`}</a>{`:`}</strong>{` provides secure, controlled access sharing of sensitive patient and COVID-19 research data.`}</p>
            </li>
            <li parentName="ol">
              <p parentName="li"><strong parentName="p"><a parentName="strong" {...{
                    "href": "https://www.covid19dataportal.org/"
                  }}>{`COVID-19 Data Portal`}</a>{`:`}</strong>{`  brings together tools and SARS-CoV-2 viral and host datasets spanning genomics, proteins, imaging, drug compounds and publications, enabling researchers to access and analyse integrated COVID-19 data easily.`}</p>
            </li>
          </ol>
          <p>{`The design of the BY-COVID project rests on this foundation, aiming to connect well-established data resources and deliver access to heterogeneous, yet interlinked and organised data, across domains and jurisdictions via the COVID-19 Data Platform components. Over the course of the project, emerging `}<a parentName="p" {...{
              "href": "https://www.covid19dataportal.org/partners"
            }}>{`national data portals`}</a>{` will be linked to the COVID-19 Data Platform, thus establishing a federated digital space for infectious disease data.`}</p>
        </Col>
      </Row>
      <Row mdxType="Row">
        <Col mdxType="Col">
          <h2>{`Media`}</h2>
          <span className="anchor" id="media"></span>
          <h3>Factsheets</h3>
          <p className="pb-2">
  Download the BY-COVID factsheet for an overview of the project. There are three different colour themes to choose from.
          </p>
          <Container className="full-width" mdxType="Container">
  <Row mdxType="Row">
    <Col className="align-items-stretch" xs={12} sm={6} lg={2} mdxType="Col">
      <Card className="mb-4 border text-center" mdxType="Card">
        <a href="/pdf/BY-COVID_factsheet_dark.pdf">
          <Card.Img variant="top" src="/BY-COVID_factsheet_dark.jpg" className="p-3" style={{
                      "background": "#fff"
                    }} />
        </a>
        <Card.Link href="/pdf/BY-COVID_factsheet_dark.pdf" className="pb-3">Download</Card.Link>
      </Card>
    </Col>
    <Col className="align-items-stretch" xs={12} sm={6} lg={2} mdxType="Col">
      <Card className="mb-4 border text-center" mdxType="Card">
        <a href="/pdf/BY-COVID_factsheet.pdf">
          <Card.Img variant="top" src="/BY-COVID_factsheet_gradient.jpg" className="p-3" style={{
                      "background": "#fff"
                    }} />
        </a>
        <Card.Link href="/pdf/BY-COVID_factsheet.pdf" className="pb-3">Download</Card.Link>
      </Card>
    </Col>
    <Col className="align-items-stretch" xs={12} sm={6} lg={2} mdxType="Col">
      <Card className="mb-4 border text-center" mdxType="Card">
        <a href="/pdf/BY-COVID_factsheet_white.pdf">
          <Card.Img variant="top" src="/BY-COVID_factsheet_white.jpg" className="p-3" style={{
                      "background": "#fff"
                    }} />
        </a>
        <Card.Link href="/pdf/BY-COVID_factsheet_white.pdf" className="pb-3">Download</Card.Link>
      </Card>
    </Col>
  </Row>
          </Container>
          <h3 className="pt-3">Graphics</h3>
          <Container className="full-width" mdxType="Container">
  <Row mdxType="Row">
    <Col className="align-items-stretch" xs={12} sm={6} lg={4} mdxType="Col">
      <Card className="mb-4" mdxType="Card">
        <Card.Img variant="top" src="/by-covid-logo-light-bg.svg" className="border p-4" style={{
                    "maxHeight": "115px",
                    "background": "#ccc"
                  }} />
        <Card.Body className="position-relative">
          <Card.Title as="h3" className="mt-0 fw-bold fs-5 lh-sm">
            Logo (light background)
          </Card.Title>
          <Card.Text className="mt-3">
            <ul className="mb-0">
              <li>
                <a href="/by-covid-logo-light-bg.svg">SVG format</a>
              </li>
              <li>
                <a href="/by-covid-logo-light-bg.png">PNG format</a>
              </li>
            </ul>
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
    <Col className="align-items-stretch" xs={12} sm={6} lg={4} mdxType="Col">
      <Card className="mb-4" mdxType="Card">
        <Card.Img variant="top" src="/by-covid-logo-dark-bg.svg" className="p-4" style={{
                    "maxHeight": "115px",
                    "background": "#ccc"
                  }} />
        <Card.Body className="position-relative">
          <Card.Title as="h3" className="mt-0 fw-bold fs-5 lh-sm">
            Logo (dark background)
          </Card.Title>
          <Card.Text className="mt-3">
            <ul className="mb-0">
              <li>
                <a href="/by-covid-logo-dark-bg.svg">SVG format</a>
              </li>
              <li>
                <a href="/by-covid-logo-dark-bg.png">PNG format</a>
              </li>
            </ul>
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
    <Col className="align-items-stretch" xs={12} sm={6} lg={4} mdxType="Col">
      <Card className="mb-4" mdxType="Card">
        <Card.Img variant="top" src="/by-covid-icon.svg" className="p-4 border" style={{
                    "maxHeight": "100px",
                    "background": "#ccc"
                  }} />
        <Card.Body className="position-relative">
          <Card.Title as="h3" className="mt-0 fw-bold fs-5 lh-sm">
            Icon
          </Card.Title>
          <Card.Text className="mt-3">
            <ul className="mb-0">
              <li>
                <a href="/by-covid-icon.svg">SVG format</a>
              </li>
              <li>
                <a href="/by-covid-icon.png">PNG format</a>
              </li>
            </ul>
          </Card.Text>
        </Card.Body>
      </Card>
    </Col>
        <p>
  {" "}
  You can find more graphics in the <a href="https://docs.google.com/presentation/d/1JSGeclDqn-OKHi0udYcNaIeSs_mMFi14/edit#slide=id.p1">
    <strong>BY-COVID brand guidelines</strong>
  </a>.
              </p>
  </Row>
          </Container>
          {/* Partners */}
          <span className="anchor" id="partners"></span>
          <div className="px-4">
            <h2>{`Partners`}</h2>
            <p>{`The project has 53 partners across 19 countries.`}</p>
            <Partners mdxType="Partners" />
          </div>
          {/* Governance */}
          <Container mdxType="Container">
  <Row className="px-4" mdxType="Row">
   <Col mdxType="Col">
   <span className="anchor" id="governance"></span>
                <h2 className="mt-4">Governance</h2>
                <p>The project has a Scientific, Ethics and Industry Advisory Board (SEIAB), with the following members:</p>
                <ul>
                  <li parentName="ul">{`Gloria Gonzalez Gacio, `}<a parentName="li" {...{
                      "href": "https://www.bitac.com/"
                    }}>{`BITAC`}</a>{`, Spain`}</li>
                  <li parentName="ul">{`Peter van Heusden,  `}<a parentName="li" {...{
                      "href": "https://www.uwc.ac.za/"
                    }}>{`U Western Cape`}</a>{`, South Africa`}</li>
                  <li parentName="ul">{`Philippe Guerin,  `}<a parentName="li" {...{
                      "href": "https://www.iddo.org/"
                    }}>{`IDDO`}</a>{`, France-United Kingdom`}</li>
                  <li parentName="ul">{`Juliane Fluck, `}<a parentName="li" {...{
                      "href": "https://www.zbmed.de/en/"
                    }}>{`ZBMed`}</a>{`, Germany`}</li>
                </ul>
              </Col>
            </Row>
          </Container>
          {/* Outreach */}
          <Container mdxType="Container">
  <Row className="px-4" mdxType="Row">
   <Col mdxType="Col">
   <span className="anchor" id="outreach"></span>
                <h2 className="mt-4">Outreach</h2>
                <h3>Public</h3>
                <p>{`We will work with patient organisations and the broader public so that people are aware of the project and can provide feedback on it. In particular, we will be asking for feedback on the ethical, social and legal aspects of opening access to infectious disease data across Europe.`}</p>
                <p>{`We will also produce materials such as infographics and cartoons on what FAIR data is (i.e. data that is `}<strong parentName="p">{`F`}</strong>{`indable, `}<strong parentName="p">{`A`}</strong>{`ccessible, `}<strong parentName="p">{`I`}</strong>{`nteroperable and `}<strong parentName="p">{`R`}</strong>{`eusable). These materials will outline the work of the project, and show how making data FAIR will help us combat disease. The materials will also include a resource pack for high school teachers, to educate high school students about BY-COVID and FAIR data. We will publish a report on the effectiveness of these public engagement activities.`}</p>
                <button className="btn btn-primary" onClick={() => {
                  navigate("/usecases");
                }}>For citizens</button>
                <h3 className="pt-4">Industry</h3>
                <p>{`We aim to map COVID-related activities in industry, and build a community of specialists to discuss visionary ideas, challenges and solutions in the area of COVID and infectious diseases.`}</p>
                <p>{`By doing this, we would like to demonstrate the importance of FAIR open data for the development of medicines, diagnostic tests and other products.
Our plan is to organise access days, aim days, Innovation and SME Forums, and publish use cases and reports describing the industry ecosystem in COVID and infectious diseases.`}</p>
                <button className="btn btn-primary" onClick={() => {
                  navigate("https://zenodo.org/records/13889809");
                }}>Read our industry report</button>
                <h3 className="pt-5">Funders</h3>
                <p>{`Showcasing the impact of the project to funding organisations is key to the continued investment in the infrastructure being developed by BY-COVID. We are planning two large stakeholder events to communicate the outputs of the project, and the value of open infectious disease data. These events will be aimed at funders and policy-makers, including the European Commission, national EOSC (European Open Science Cloud) representatives, and relevant EU agencies.`}</p>
                <p>{`We will further demonstrate the importance of FAIR COVID-19 and other infectious disease data and software to support innovation in academia and industry.`}</p>
                <p className="fw-bold mt-4">
                  <button className="btn btn-primary" onClick={() => {
                    navigate("https://by-covid.org/news/bycovid-final-policy-brief/");
                  }}>Read our policy briefs</button>
                </p>
                <span className="anchor" id="contact"></span>
                <h2>{`Contact us`}</h2>
                <p>{`For any enquiries please email `}<a parentName="p" {...{
                    "href": "mailto:by-covid-admin@elixir-europe.org"
                  }}>{`by-covid-admin@elixir-europe.org`}</a>{`.`}</p>
   </Col>
  </Row>
          </Container>
          {/* External projects */}
          <span className="anchor" id="external-projects"></span>
          <div className="px-4">
            <h2>{`Alignment with external projects and initiatives`}</h2>
            <p>{`In addition to the project partners listed above, BY-COVID work closely with a number of external projects and organisations.`}</p>
            <p>{`Our sibling project is `}<a parentName="p" {...{
                "href": "https://isidore-project.eu/"
              }}>{`ISIDORe`}</a>{` (Integrated Services for Infectious Disease) which provides an integrated portfolio of cutting-edge research services and resources to study epidemic-prone pathogens including SARS-CoV-2.`}</p>
            <h3>{`Infectious diseases projects`}</h3>
            <ul>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://isidore-project.eu/"
                }}>{`ISIDORe`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://recodid.eu/"
                }}>{`ReCoDID`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://vaccelerate.eu/"
                }}>{`Vaccelerate`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://www.veo-europe.eu/"
                }}>{`VEO`}</a></li>
            </ul>
            <h3>{`European Commission projects`}</h3>
            <ul>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://b1mg-project.eu"
                }}>{`B1MG`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://www.egi.eu/projects/egi-ace/"
                }}>{`EGI-ACE`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://elixir-europe.org/about-us/how-funded/eu-projects/converge"
                }}>{`ELIXIR-CONVERGE`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://www.eosc-life.eu/"
                }}>{`EOSC-Life`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://www.phiri.eu/"
                }}>{`PHIRI`}</a></li>
              <li parentName="ul"><a parentName="li" {...{
                  "href": "https://sshopencloud.eu/"
                }}>{`SSHOC`}</a></li>
            </ul>
          </div>
        </Col>
      </Row>
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      